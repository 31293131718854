<template>
    <div id="edit-asset-of-asset-module-form-component">
        <v-form ref="form">
            <template v-if="user">
                <p>
                    <strong>
                        {{ $t('edit_asset_of_asset_module_form_component.customer_link_to_equipment') }}
                    </strong>
                </p>

                <v-select
                    v-model="id_client"
                    :items="clients"
                    :label="$t('edit_asset_of_asset_module_form_component.customer')"
                    :loading="fetching_client_of_user"
                    :menu-props="{ bottom: true, offsetY: true }"
                    :rules="[(v) => !!v || $t('generic_form_component.required_fields')]"
                    filled
                    item-text="client_space_name"
                    item-value="id_client"
                    @change="handleClientChange"
                >
                    <template v-slot:prepend-item>
                        <v-btn block text @click="openAddClientDialog">
                            <v-icon>mdi-plus</v-icon>
                            {{ $t('global.add') }}
                        </v-btn>
                    </template>
                </v-select>
            </template>

            <asset-domicile ref="assetDomicile" :uid_client="uid_client" />

            <p>
                <strong>{{ $t('asset_specification.equipment_information') }}</strong>
            </p>

            <template v-if="!fetching_liquids">
                <div>
                    <v-text-field
                        v-model="asset.code_asset"
                        :label="$t('edit_asset_of_asset_module_form_component.ref_equipment')"
                        autofocus
                        filled
                    />

                    <v-text-field
                        v-model="asset.name"
                        :label="$t('edit_asset_of_asset_module_form_component.name_equipment')"
                        :rules="[(v) => !!v || $t('generic_form_component.required_fields')]"
                        filled
                    />

                    <v-row class="mb-2">
                        <v-col
                            v-for="index in module.total_models"
                            :key="index"
                            :lg="module.total_models === 1 ? 12 : 3"
                            :md="module.total_models === 1 ? 12 : 6"
                            cols="12"
                        >
                            <p class="mb-0 font-weight-medium text-uppercase">{{ module.name }} {{ index }}</p>

                            <asset-model-form-wrapper-component
                                :asset_liquids="asset_liquids"
                                :asset_module="module"
                                :column="index - 1"
                                :configuration="default_models_configuration[index - 1]"
                                v-on:update:model="handleModelConfigurationUpdate"
                                :authorize_config_selection="!(module.total_models === 1)"
                            />
                        </v-col>
                    </v-row>
                </div>

                <v-row>
                    <v-col>
                        <v-btn block class="white--text" color="grey" large @click="previousStep()">
                            {{ $t('back') }}
                        </v-btn>
                    </v-col>

                    <v-col>
                        <v-btn :loading="installing_asset" block color="primary" large @click="prepareInstallDevice()">
                            {{ $t('validate') }}
                        </v-btn>
                    </v-col>
                </v-row>
            </template>

            <template v-else>
                <v-skeleton-loader type="image" />
            </template>
        </v-form>

        <add-edit-client-dialog-component ref="addClientDialog" v-on:added:client="fetchMinimalProfessionalClientsByProfessionalUid()" />
    </div>
</template>

<script>
import AssetModelFormWrapperComponent from '@/components/installation-step/installation-step-4/generic/AssetModelFormWrapperComponent.vue'
import AssetDomicile from '@/components/installation-step/installation-step-4/asset-domicile/asset-domicile.vue'
import AddEditClientDialogComponent from '@/components/installation-step/installation-step-4/AddClientDialogComponent.vue'

export default {
    name: 'EditAssetOfAssetModuleFormComponent',
    components: { AddEditClientDialogComponent, AssetDomicile, AssetModelFormWrapperComponent },
    props: {
        asset_type: {
            type: Object,
            required: true,
        },
        id_professional: {
            type: Number,
            required: true,
        },
        uid_professional: {
            type: String,
            required: true,
        },
        id_device: {
            type: Number,
            required: true,
        },
        user: {
            required: true,
        },
    },
    data() {
        return {
            asset: {},
            module: {},
            asset_liquids: [],
            default_models_configuration: [],
            fetching_module: true,
            fetching_liquids: true,
            installing_asset: false,

            models_configurations: [],

            fetching_client_of_user: false,
            clients: [],
            id_client: null,
            uid_client: null,
        }
    },
    created() {
        this.fetchAssetModuleById()
        if (this.user) {
            this.fetchMinimalProfessionalClientsByProfessionalUid()
        }
    },
    watch: {
        asset_type() {
            this.fetchAssetModuleById()
        },
    },
    methods: {
        previousStep() {
            this.$emit('previousStep')
        },
        handleClientChange(id_client) {
            this.uid_client = this.clients.find((client) => client.id_client === id_client).uid
        },
        fetchMinimalProfessionalClientsByProfessionalUid() {
            this.fetching_client_of_user = true
            this.axios
                .get(`/api/v1/professional/${this.uid_professional}/clients/public`)
                .then((success) => {
                    this.clients = success.data.data
                    if (this.clients.length === 1) {
                        this.id_client = this.clients[0].id_client
                    }
                })
                .catch((error) => {
                    this.manageError(error)
                })
                .finally(() => {
                    this.fetching_client_of_user = false
                })
        },
        fetchAssetModuleById() {
            this.fetching_module = true
            this.axios
                .get(`/api/v1/module/${this.asset_type.id_module}`)
                .then((success) => {
                    this.module = success.data.data
                    this.fetchAssetLiquidByIdModule()
                })
                .catch((error) => {
                    this.manageError(error)
                })
                .finally(() => {
                    this.fetching_module = false
                })
        },

        setupEmptyAsset() {
            Array.from(Array(this.module.total_models).keys()).forEach(() => {
                this.default_models_configuration.push({
                    is_required: true,
                    id_asset_model: this.module.asset_models[0].id_asset_model,
                    id_asset_liquid: null,
                })
            })
        },
        fetchAssetLiquidByIdModule() {
            this.fetching_liquids = true
            this.axios
                .get(`/api/v1/asset_liquid/professional/${this.id_professional}/module/${this.module.id_module}`)
                .then((success) => {
                    this.asset_liquids = success.data.data
                    this.setupEmptyAsset()
                })
                .catch((error) => {
                    this.manageError(error)
                })
                .finally(() => {
                    this.fetching_liquids = false
                })
        },

        handleModelConfigurationUpdate(data) {
            this.models_configurations[data.column] = data.model_configuration
        },

        async prepareInstallDevice() {
            const valid = await this.$refs.form.validate()
            let domicileModel = JSON.parse(JSON.stringify(this.$refs.assetDomicile.domicile))
            domicileModel.route = this.$refs.assetDomicile.domicile.name

            domicileModel.id_domicile = this.$refs.assetDomicile.id_domicile

            if (valid) {
                this.models_configurations.forEach((model_configurations, column) => {
                    this.models_configurations[column].configuration = model_configurations.is_required
                        ? model_configurations.configuration
                        : null
                })

                this.addAsset({
                    asset_models: this.models_configurations,
                    asset_name: this.asset.name,
                    asset_code: this.asset.code_asset,
                    id_module: this.module.id_module,
                    id_device: this.id_device,
                    client: this.id_client ? { id_client: this.id_client } : null,
                    domicile_name: this.$refs.assetDomicile.domicile_name,
                    domicile: domicileModel,
                })
            }
        },

        addAsset(newAsset) {
            this.installing_asset = true
            this.axios
                .post('/api/v1/installation/device', newAsset)
                .then(() => {
                    this.$emit('goToResult')
                })
                .catch((error) => {
                    this.manageError('error', this.$t('step_4.error_during_installation'), error)
                })
                .finally(() => {
                    this.installing_asset = false
                })
        },
        openAddClientDialog() {
            this.$refs.addClientDialog.openAddClientDialog(this.uid_professional)
        },
    },
}
</script>

<style>
.white-input .v-input__slot {
    background-color: white !important;
    border: white !important;
}
</style>
